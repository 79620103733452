exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-recommended-index-js": () => import("./../../../src/pages/recommended/index.js" /* webpackChunkName: "component---src-pages-recommended-index-js" */),
  "component---src-pages-recommended-stories-js": () => import("./../../../src/pages/recommended/stories.js" /* webpackChunkName: "component---src-pages-recommended-stories-js" */),
  "component---src-pages-recommended-videos-js": () => import("./../../../src/pages/recommended/videos.js" /* webpackChunkName: "component---src-pages-recommended-videos-js" */),
  "component---src-pages-stack-js": () => import("./../../../src/pages/stack.js" /* webpackChunkName: "component---src-pages-stack-js" */),
  "component---src-pages-tools-analog-clock-js": () => import("./../../../src/pages/tools/analog-clock.js" /* webpackChunkName: "component---src-pages-tools-analog-clock-js" */),
  "component---src-pages-tools-index-js": () => import("./../../../src/pages/tools/index.js" /* webpackChunkName: "component---src-pages-tools-index-js" */),
  "component---src-pages-tools-qr-code-generator-js": () => import("./../../../src/pages/tools/qr-code-generator.js" /* webpackChunkName: "component---src-pages-tools-qr-code-generator-js" */),
  "component---src-pages-topics-js": () => import("./../../../src/pages/topics.js" /* webpackChunkName: "component---src-pages-topics-js" */),
  "component---src-pages-tutorials-js": () => import("./../../../src/pages/tutorials.js" /* webpackChunkName: "component---src-pages-tutorials-js" */),
  "component---src-templates-article-post-js": () => import("./../../../src/templates/article-post.js" /* webpackChunkName: "component---src-templates-article-post-js" */),
  "component---src-templates-topic-post-js": () => import("./../../../src/templates/topic-post.js" /* webpackChunkName: "component---src-templates-topic-post-js" */)
}

